<template>
  <div class="TitleRight">
    <div class="TitleRight-Header">
      <h2 class="Title Title--h2 TitleRight-Title" v-html="info.title"></h2>
      <p class="Text TitleRight-Text" v-html="info.text"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleRight",
  props: {
    info: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss">
.TitleRight {
  &-Header {
    margin-bottom: 45px;
    text-align: center;
    @media (min-width: $screen-xl) {
      position: relative;
      width: 889px;
      left: 345px;
      margin-bottom: 60px;
      text-align: left;
    }
  }

  &-Title {
    margin-bottom: 20px;
    padding: 0 50px;
    font-size: 28px;
    @media (min-width: $screen-l) {
      margin-bottom: 25px;
      padding: 0;
      font-size: 70px;
    }
  }
}
</style>
<template>
  <div class="JoinUs">
    <div class="JoinUs-Label">Хочешь также?</div>
    <button
      class="JoinUs-Button Button"
      type="button"
      @click.prevent="scrollTo('form')"
    >
      <span class="Button-Text">Стань частью Syndicate</span>
      <span class="Button-Shadow"></span>
    </button> 
  </div>  
</template>

<script>
import { scrollTo } from '../assets/utils/utils'

export default {
  name: 'JoinUs',
  methods: {
    scrollTo
  }
}
</script>

<style lang="scss">
.JoinUs {
  text-align: center;

  @media (min-width: $screen-l) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
  }

  &-Label {
    margin-bottom: 22px;

    @media (min-width: $screen-l) {
      margin-right: 24px;
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      margin-right: 48px;
    }
  }

  &-Button {
    display: inline-block;
  }
}
</style>

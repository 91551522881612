<template>
  <div class="OurValues Wrapper">
    <div class="OurValues-List">
      <div
        class="OurValues-ListItem"
        v-for="(value, i) in ourValues"
        :key="i"
      >
        <h3
          v-if="i === 0"
          class="OurValues-Title Title Title--h3"
        >
          Наши ценности:
        </h3>

        <div class="OurValuesCard">
          <div
            class="OurValuesCard-Icon"
            :style="{ 'background-image': 'url(' + require(`@/assets/images/${value.icon}`) + ')' }"
          ></div>
          <h4 class="OurValuesCard-Title Title Title--h5">{{ value.title }}</h4>
          <p class="OurValuesCard-Text Text" v-html="value.text"></p>
        </div>

        <JoinUs
          v-if="i + 1 === ourValues.length && isScreenLarge"
          class="OurValues-JoinUs"
        />
      </div>

      <div class="OurValues-ListItem">
        <div class="OurValues-Video">
          <h3 class="OurValues-VideoTitle Title Title--h5">Мероприятия Syndicate</h3>

          <div class="OurValues-VideoCard">
            <div
              class="VideoCard"
              @click="openVideo('video', 'https://www.youtube.com/watch?v=EU2ux4Fp-ro')"
            >
              <div class="VideoCard-Image">
                <img
                  src="@/assets/images/workplay-video-2.jpg"
                  alt="photo"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <JoinUs
      v-if="!isScreenLarge"
      class="OurValues-JoinUs"
    />
    
    <div class="OurValues-Photos OurValuesPhotos">
      <div class="OurValuesPhotos-Item">
        <img src="@/assets/images/workplay-5.jpg" alt="photo">
      </div>
      <div class="OurValuesPhotos-Item">
        <img src="@/assets/images/workplay-2.jpg" alt="photo">
      </div>
      <div class="OurValuesPhotos-Item">
        <img src="@/assets/images/workplay-11.jpg" alt="photo">
        <img src="@/assets/images/workplay-7.jpg" alt="photo">
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import JoinUs from './JoinUs'
import BaseModal from "@/components/BaseModal"

export default {
  name: "OurValues",
  components: {
    JoinUs
  },
  data() {
    return {
      isScreenLarge: null
    }
  },
  computed: {
    ...mapGetters({
      ourValues: 'getOurValues'
    })
  },
  mounted() {
    this.checkWindowSize()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize)
  },
  methods: {  
    onResize() {
      this.checkWindowSize()
    },
    checkWindowSize() {
      this.isScreenLarge = window.matchMedia('(min-width: 960px)').matches
    },
    openVideo(content, videoUrl) {
      this.$modal.show(
          BaseModal,
          { content, videoUrl },
          {
            name: 'Modal',
            width: 768,
            height: 'auto',
            adaptive: true,
            scrollable: true,
            clickToClose: false
          }
      );
    }
  }, 
}
</script>

<style lang="scss">
.OurValues {
  margin-bottom: 40px;

  @media (min-width: $screen-l) {
    margin-bottom: 100px;
  }

  &-Title {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 36px;
    }      
  }

  &-List {
    @media (min-width: $screen-l) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 32px;
      margin-bottom: 80px;
    }

    @media (min-width: $screen-xl) {
      gap: 0 71px;
    }
  }

  &-ListItem {
    margin-bottom: 16px;

    @media (min-width: $screen-l) {
      margin-bottom: 0;
      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 2;
        padding-bottom: 80px;
      }

      &:nth-child(3) {
        order: 4;
      }

      &:nth-child(4) {
        order: 3;
        padding-top: 80px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-VideoTitle {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 39px;
    }
  }

  &-VideoCard {
    border: 4px solid var(--color-border);
    padding: 20px 23px;

    @media (min-width: $screen-l) {
      padding: 36px 32px;
    }

    @media (min-width: $screen-xl) {
      padding: 48px 46px;
    }
  }

  &-JoinUs {
    margin-top: 24px;
    margin-bottom: 40px;

    @media (min-width: $screen-l) {
      margin-top: 91px;
      margin-bottom: 0;
    }
  }
}

.OurValuesCard {
  --border-width: 4px;

  position: relative;
  border: var(--border-width) solid var(--color-border);
  border-bottom-color: var(--color-text-main2);
  padding: 28px 18px;
  text-align: center;

  @media (min-width: $screen-l) {
    padding: 32px 32px 42px;
    text-align: left;
  }

  @media (min-width: $screen-xl) {
    padding: 72px 62px 62px;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: calc(-1 * var(--border-width));
    width: var(--border-width);
    height: 21px;
    background-image: linear-gradient(to top, var(--color-text-main2) 0, rgba(#1f2515, 0) 100%);
  }

  &::before {
    left: calc(-1 * var(--border-width));
  }

  &::after {
    right: calc(-1 * var(--border-width));
  }

  &-Icon {
    height: 32px;
    margin-bottom: 10px;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 0;

    @media (min-width: $screen-l) {
      height: 49px;
      margin-bottom: 32px;
      background-position: 0 0;
    }
  }

  &-Title {
    margin-bottom: 16px;

    @media (min-width: $screen-l) {
      margin-bottom: 32px;
    }
  }
}

.OurValuesPhotos {
  display: flex;
  flex-wrap: wrap;
  margin-right: -6px;
  margin-left: -6px;
  
  @media (min-width: $screen-l) {
    flex-wrap: nowrap;
    margin-right: -14px;
    margin-left: -14px;
  }

  &-Item {
    flex: 1 1 auto;
    margin-bottom: 12px;
    padding-right: 6px;
    padding-left: 6px;

    @media (min-width: $screen-l) {
      margin-bottom: 0;
      padding-right: 14px;
      padding-left: 14px;      
    }

    &:nth-child(1) {
      width: 33%;

      @media (min-width: $screen-l) {
        width: auto;
      }
    }

    &:nth-child(2) {
      width: 67%;

      @media (min-width: $screen-l) {
        width: auto;
      }
    }

    &:last-child {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0 12px;
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 0;

      @media (min-width: $screen-l) {
        display: block;
        width: auto;
        flex: 1 1 auto;
        gap: 0;
      }
    }

    img {
      display: block;

      @media (min-width: $screen-l) {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }

  }
}
</style>

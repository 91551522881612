<template>
  <section id="advantages" class="Advantages">
    <div class="Wrapper">
      <TitleRight :info="titleInfo" id="advantages-title"/>
      
      <div class="Advantages-List" v-show="$i18n.locale === 'en'">
        <template v-for="(card,i) in cards">
          <BaseCard :key="i" :card-info="card"/>
        </template>
      </div>
    </div>

    <OurValues v-show="$i18n.locale === 'ru'"/>

    <div class="Wrapper" v-show="$i18n.locale === 'ru'">
      <Possibilities />
    </div>
  </section>
</template>

<script>
import BaseCard from "./BaseCard";
import Possibilities from "./Possibilities";
import TitleRight from "./TitleRight";
import OurValues from "./OurValues";

export default {
  name: "Advantages",
  components: { TitleRight, Possibilities, BaseCard, OurValues },
  data() {
    return {
      cards: [
        {name: "creative", width: 142, height: 161, title: this.$t('advatages.card1.title'), text: this.$t('advatages.card1.text')},
        {name: "anti-fraud", width: 148, height: 166, title: this.$t('advatages.card2.title'), text: this.$t('advatages.card2.text')},
        {name: "automation", width: 161, height: 161, title: this.$t('advatages.card3.title'), text: this.$t('advatages.card3.text')},
      ],
      titleInfo: {
        title: this.$t('advatages.title'),
        text: this.$t('advatages.text')
      }
    }
  },
}
</script>

<style lang="scss">
.Advantages {
  padding: 35px 0 25px;
  scroll-margin-top: 70px;

  @media (min-width: $screen-l) {
    padding: 49px 0 70px;
  }

  &-Text {
    font-size: 17px;
  }

  &-List {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0 50px;
    justify-content: center;

    .BaseCard {
      &:before {
        top: 77%;
        left: -20px;
        width: 30px;

        @media (min-width: $screen-l) {
          width: 31px;
          top: 84.5%;
        }
      }

      &:after {
        top: 77%;
        right: -11px;
        width: 30px;

        @media (min-width: $screen-l) {
          top: 84%;
          width: 50px;
          right: -18px;
        }
      }
    }

    @media (min-width: $screen-l) {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;

      .BaseCard {
        width: 340px;
      }
    }

    @media (min-width: $screen-xl) {
      grid-template-columns: repeat(3, 350px);
      grid-gap: 0 88px;
    }
  }
}
</style>

<template>
  <div class="Possibilities">
    <h3 class="Title Title--h3 Possibilities-SliderTitle">
      Мы предоставим вам все <br>
      для развития команды:
    </h3>
    <div class="Possibilities-List">
      <template v-for="(card,i) in cards">
        <BaseCard :key="i" :card-info="card" :class="{'BaseCard--en': $i18n.locale === 'en'}"/>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Possibilities",
  data() {
    return {
      cards: [
        {name: "expertise", width: 161, height: 161, title: "Экспертизу<br> в гемблинге<br> с 2016"},
        {name: "unlimit", width: 161, height: 161, title: "Безлимитную оборотку"},
        {
          name: "infrastructure", width: 161, height: 161,
          title: "Инфраструктуру для роста:",
          text: "Приложения IOS/Android<br> Аккаунты <br>Офферы"
        },
        {name: "finance", width: 161, height: 161, title: "Закроем юридические<br> и финансовые вопросы"},
      ],
    }
  },
}
</script>

<style lang="scss">
.Possibilities {
  &-List {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0 50px;
    justify-content: center;

    @media (min-width: $screen-l) {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
    }

    @media (min-width: $screen-xl) {
      grid-template-columns: repeat(4, 280px);
      grid-gap: 0 36px;
    }
  }


  .BaseCard {
    //height: 380px;
    @media (min-width: $screen-l) {
      width: 280px;
      height: 454px;
      padding: 72px 14px 60px 23px;
    }

    &:before {
      top: 81.5%;

      @media (min-width: $screen-l) {
        top: 84%;
      }
    }

    &:after {
      top: 82%;

      @media (min-width: $screen-l) {
        top: 84%;
      }
    }
  }
}
</style>